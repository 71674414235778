/*
 *  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

// scrollbar tinkering

@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #444 transparent;
  }
}

::-webkit-scrollbar {
  width: .9em;
}

::-webkit-scrollbar-track {
  background: var(--cui-body-bg, #fff);
  border-radius: 100vw;
  margin-block: .2em;
}

::-webkit-scrollbar-thumb {
  background: #999;
  border: .2em solid var(--cui-body-bg, #fff);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: #444;
}

.dark-theme::-webkit-scrollbar-thumb {
  background: #444;
}

.dark-theme::-webkit-scrollbar-thumb:hover {
  background: #999;
}
