/*
 *  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

// Here you can add other styles

@-webkit-keyframes roll {
    from { -webkit-transform: rotate(0deg) }
    to { -webkit-transform: rotate(360deg) }
}

@keyframes roll {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

.easter-egg {
    -webkit-animation-name: roll;
    animation-name: roll;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}