/*
 *  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "../../node_modules/@merlot-education/m-basic-ui/src/scss/variables_light";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "../../node_modules/@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

// perfect scrollbar theming for use with [perfectScrollbar] directive
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

